.wl_activeTabs {
  /* border-bottom: 2px solid dodgerblue; */
  text-decoration: none;
  color: #495057;
  background-color: #fff;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.wl_tabsLinks:hover,
.wl_tabsLinks {
  cursor: pointer;
  text-decoration: none;
  padding: 10px;
  font-size: 16px;
}

.wl-navLinkDiv {
  margin: 0 0 0 0;
}

.wl_manageContainer {
  /* padding-top: 10px; */
}

.wl-pipeviewColumn {
  text-align: center;
  min-width: 305px;
  max-width: 305px;
  /* background-color: #e0e0e0; */
  /* border-right: 1px solid rgb(232, 232, 232); */
  border-radius: 10px;
}

.wl-pipeviewColumn-Priority {
  width: 350px;
  border-radius: 10px;
}

.wl-pipeviewContainer {
  overflow: auto;
  min-height: 65vh;
}

.wl-pipeviewContainer-Status {
  overflow: auto;
  min-height: 62vh;
}

.wl-bg-D3E0EA {
  background-color: #d3e0ea;
}

.wl-bg-F5F4F4 {
  background-color: #f5f4f4;
}

.wl_viewBtn {
  cursor: pointer;
}

.viewBtnDiv {
  margin-left: 1.25rem;
  background: #efe9e9;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.viewBtn {
  cursor: pointer;
  height: 20px;
  min-width: 20px;
}

.wl-addBtn {
  height: 25px;
  min-width: 25px;
  color: #3484e2;
}

.wl-addBtn:hover {
  color: #0958b3;
}

.editableWorkload {
  cursor: pointer;
}

.editableWorkload_title {
  width: 12rem;
}

.wl_logDiv {
  border: 1px solid #ccc;
  max-height: 200px;
  overflow: auto;
  background-color: #e9ecef;
  color: #495057;
  border-radius: 0.25rem;
  font-size: 13px;
  padding: 5px 0 5px 18px;
}

.slaDiv {
  border: 1px solid #ccc;
  background-color: #e9ecef;
  color: #495057;
  margin: 0;
  padding: 6px;
  border-radius: 0.25rem;
  font-size: 13px;
}

.wl-bulkEdit-container {
  /* background: #F3F2F2; */
  width: 25vw;
  height: 100%;
}

.wl-filter {
  background-color: #f7f9fe;
  padding: 10px 20px 0px 20px;
}

.wl_parentTicketPopover {
  position: absolute;
  z-index: 2000;
  top: 3rem;
  right: 0em;
  background: #fff;
  width: 145%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #ced4dabb;
  border-radius: 0.25rem;
  box-shadow: 2px 2px #d1d1d1;
}

.wl_parentTicketPopover div span {
  width: max-content;
  margin: 0 0.5rem 0 0.25rem;
}

.wl_parentTicketSearchResult {
  max-height: 10rem;
  overflow: auto;
  margin: 0.3rem 0 0.3rem 0.3rem;
  border-radius: 0.25rem;
  width: 100%;
  padding: 0 0.5rem 0 0;
}

.wl_parentTicketSearchResult div {
  background: rgb(193, 193, 193);
  padding: 0 0.25rem;
}

.wl_parentTicketSearchResult li {
  text-decoration: none;
  list-style: none;
  border-bottom: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.25rem;
  margin: 0.25rem 0;
  cursor: pointer;
  width: 100%;
  background: white;
}

.wl_parentTicketSearchResult li:hover {
  background: #e9ecef;
  color: #111;
}

.wl_parentTicketSearchResult .selected {
  background: dodgerblue;
  color: white;
}

.parentWorkload {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
}

.wl_childTicketDiv {
  min-height: 38px;
  margin: 0 0 0 5%;
}

.createWorkloadForm {
  max-width: 1200px;
  margin: auto;
}

.bg-lightGrey {
  background: #f1f1f1;
}

.attachmentBtn {
  border: 1px solid #888889;
  font-size: 12px;
  border-radius: 5px;
  padding: 0px 5px;
}

.badgeCustom_a {
  text-decoration: none;
  color: inherit;
}

.badgeCustom_a:hover {
  color: inherit;
}

.parentTicket_col {
  width: 10rem;
}

.wl_customerPopover {
  position: absolute;
  z-index: 200;
  background: #f0f0f0;
  flex-direction: column;
  max-height: 13rem;
  overflow: auto;
  min-width: 20rem;
  padding: 0.2rem;
  border-radius: 0.2rem;
}

.wl_customerPopover li {
  text-decoration: none;
  list-style: none;
  border-bottom: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.25rem;
  margin: 0.25rem 0;
  cursor: pointer;
  width: 100%;
  background: white;
}

.wl_customerPopover li:hover {
  background: #e9ecef;
  color: #111;
}

.wl_customerPopover .selected {
  background: dodgerblue;
  color: white;
}

.wl_historyDiv {
  border: 1px solid #ccc;
  max-height: 250px;
  overflow: auto;
  background-color: #e9ecef;
  color: #495057;
  border-radius: 0.25rem;
  font-size: 13px;
}

.wl_historyDiv ul {
  list-style: none;
  margin-bottom: 0;
  width: 100%;
}

.workHistory_item {
  background: #fff;
  border-radius: 10px;
  padding: 0 0.1rem;
}

.workHistory_contentHidden {
  max-width: 70ch;
  max-height: 1.3rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

.workHistory_subtext {
  font-size: 0.8rem;
  background: #cecece;
  padding: 0.3rem;
  border-radius: 5px;
}

.xs-fontSize {
  font-size: 0.8rem;
}

.workHistory_status {
  background: #f0f0f0;
  color: black;
  padding: 2px;
  border-radius: 5px;
}

.wl_logDiv::-webkit-scrollbar {
  width: 5px;
}

.wl_logDiv::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fcfcfc;
  border-radius: 2px;
  width: 7px;
}

.wl_logDiv::-webkit-scrollbar-thumb {
  background: #fcfcfc;
  border-radius: 2px;
}

.wl_historyDiv::-webkit-scrollbar {
  width: 5px;
}

.wl_historyDiv::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ecebeb;
  border-radius: 2px;
  width: 7px;
}

.wl_historyDiv::-webkit-scrollbar-thumb {
  background: #fcfcfc;
  border-radius: 2px;
}

.workloadTable-menuBtn {
  width: 20px;
  text-align: center;
}

.workloadTable-menuBtn:hover {
  background-color: white;
}

.workloadTable-menu {
  display: flex;
  flex-direction: column;
  padding: 0.25rem;
}

.workloadTable-menu span {
  cursor: pointer;
  margin: 0.1rem 0;
}

.workloadTable-menu span:hover {
  background: #f0f0f0;
}
