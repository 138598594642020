.grid-container {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  min-height: 20%;
  justify-content: center;
  align-items: center;
}

.target {
  width: 10px;
  height: 10px;
  background: grey;
  border-radius: 50%;
  margin-top: 10px;
  margin-left: 4px;
}

h6 {
  font-size: 10px;
  margin-top: 9px;
  margin-left: 2px;
}

.target-circle,
.target-circlel1,
.target-circlel2,
.target-circlel3,
.target-circlehr {
  width: 15px;
  height: 15px;
  background: grey;
  border-radius: 50%;
  position: absolute;
  top: 50px;
  left: 7rem;
}

.target-circle.status1 {
  background: #e5de00;
}

.target-circle.status2,
.target-circle.status5,
.target-circle.status7,
.target-circle.status9,
.target-circle.status11,
.target-circle.status12,
.target-circle.status4,
.target-circle.status6,
.target-circle.status8,
.target-circle.status10,
.target-circle.status13,
.target-circle.status14,
.target-circle.status15,
.target-circle.status16,
.target-circle.status17,
.target-circle.status18 {
  background: #28a745;
  ;
}

.target-circle.status3 {
  background: red;
}

.target-circlel1.status2 {
  background: #e5de00;
}

.target-circlel1.status4,
.target-circlel1.status6,
.target-circlel1.status12,
.target-circlel1.status7,
.target-circlel1.status8,
.target-circlel1.status9,
.target-circlel1.status10,
.target-circlel1.status11,
.target-circlel1.status13,
.target-circlel1.status14,
.target-circlel1.status15,
.target-circlel1.status16,
.target-circlel1.status17,
.target-circlel1.status18 {
  background: #28a745;
}

.target-circlel1.status5 {
  background: red;
}

.target-circlel2.status4 {
  background: #e5de00;
}

.target-circlel2.status6,
.target-circlel2.status7,
.target-circlel2.status8,
.target-circlel2.status9,
.target-circlel2.status10,
.target-circlel2.status11,
.target-circlel2.status12,
.target-circlel2.status13,
.target-circlel2.status14,
.target-circlel2.status15,
.target-circlel2.status16,
.target-circlel2.status17,
.target-circlel2.status18 {
  background: #28a745;
}

.target-circlel2.status7 {
  background: red;
}

.target-circlel3.status6 {
  background: #e5de00;
}

.target-circlel3.status8,
.target-circlel3.status8,
.target-circlel3.status10,
.target-circlel3.status11,
.target-circlel3.status12,
.target-circlel3.status13,
.target-circlel3.status14,
.target-circlel3.status15,
.target-circlel3.status16,
.target-circlel3.status17,
.target-circlel3.status18 {
  background: #28a745;
}

.target-circlel3.status9 {
  background: red;
}

.target-circlehr.status8 {
  background: #e5de00;
}

.target-circlehr.status10,
.target-circlehr.status12,
.target-circlehr.status13,
.target-circlehr.status14,
.target-circlehr.status15,
.target-circlehr.status16,
.target-circlehr.status14,
.target-circlehr.status15,
.target-circlehr.status16,
.target-circlehr.status17,
.target-circlehr.status18 {
  background: #28a745;
}

.target-circlehr.status11 {
  background: red;
}


.review-circle,
.review-circlel1,
.review-circlel2,
.review-circlel3,
.review-circlehr {
  width: 15px;
  height: 15px;
  background: grey;
  border-radius: 50%;
  position: absolute;
  bottom: 50px;
  left: 7rem;
}

.review-circle.status13 {
  background: #e5de00;
}

.review-circle.status14,
.review-circle.status15,
.review-circle.status16,
.review-circle.status17,
.review-circle.status18 {
  background: #28a745;
}

.review-circlel1.status14 {
  background: #e5de00;
}

.review-circlel1.status15,
.review-circlel1.status16,
.review-circlel1.status17,
.review-circlel1.status18 {
  background: #28a745;
}

.review-circlel2.status15 {
  background: #e5de00;
}

.review-circlel2.status16,
.review-circlel2.status17,
.review-circlel2.status18 {
  background: #28a745;
}

.review-circlel3.status16 {
  background: #e5de00;
}

.review-circlel3.status17,
.review-circlel3.status18 {
  background: #28a745;
}

.review-circlehr.status17 {
  background: #e5de00;
}

.review-circlehr.status18 {
  background: #28a745;
}

.appraisee1 {
  background-image: url("/images/appraisee1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-width: 16.8rem;
  min-height: 10rem;

  position: relative;
}

.l1 {
  background-image: url("/images/l1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  min-width: 16.8rem;
  min-height: 10rem;

  position: relative;
}

.l2 {
  background-image: url("/images/l2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  min-width: 16.8rem;
  min-height: 10rem;

  position: relative;
}


.l3 {
  background-image: url("/images/l3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  min-width: 16.8rem;
  min-height: 10rem;

  position: relative;
}

.hr {
  background-image: url("/images/HR.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  min-width: 13.8rem;
  min-height: 10.3rem;

  position: relative;
}

.medium_device_status {
  display: none
}

.otherStyle {
  display: flex;
  justify-content: center;
  align-items: center;

}

.other-Style {
  display: flex;
  justify-content: center;
  align-items: center;

}

@media screen and (max-width: 950px) {
  .grid-container {
    display: none;
  }

  .otherStyle {
    display: none;

  }

  .medium_device_status {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 5px;
    justify-content: center;

  }

  .img_small {
    width: 50px;
    height: 50px;
  }

}