body {
  background-color: #f7f9fe !important;
}

.logo {
  width: 50%;
}

.welcome_image {
  width: -webkit-fill-available;
}

.welcome {
  margin-left: 6.5rem;
  width: 187vh;
  height: 90vh;
}

.brdr {
  border: 1px solid gray;
  border-radius: 2px;
}

.sidebarMain .active {
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

.removeItem {
  color: #c7c6c6;
  cursor: pointer;
}

.isCursor {
  color: #fff;
  cursor: pointer;
}

.isPointer {
  cursor: pointer;
}

.resumeAvailable {
  color: #ff9b58;
  cursor: pointer;
}

.displayOn {
  display: initial;
}

.displayOff {
  display: none;
}

.displayOn2 {
  display: initial;
  transition: all 1.5s ease-in-out;
}

.iconGold {
  color: black;
}

.displayOff2 {
  display: none;
  transition: all 1.5s ease-in-out;
}

.candidateprofile_picture {
  max-width: 100%;
  margin: 0px auto;
}

.profileOptionsButtonOff {
  display: none;
}

.isIcon {
  width: 24px !important;
  height: 24px !important;
}

.longTextOnButton {
  font-size: 12px !important;
}

.commentBubble {
  padding: 10px !important;
  background: whitesmoke;
  border-radius: 5px;
  margin-bottom: 5px;
}

.commentBubble svg {
  /* float:right; */
}

.title {
  font-size: 12px;
}

.subtitle {
  font-size: 10px;
}

.isPointer {
  cursor: pointer;
}

.isItemOnLits {
  border-width: 60%;
  margin-left: 2px;
}

.hoverPosition:hover {
  background: #2a90d4;
  color: whitesmoke;
  border-bottom: 2px solid rgb(255, 255, 255);
}

.testangel {
  max-height: 250px;
  min-height: 250px;
  overflow-y: scroll;
}

.navbar_shadow {
  box-shadow: 1px 0px 4px rgb(82, 82, 82);
}

.sidebarMain {
  border-right: 0px solid #dee2e6 !important;
  box-shadow: 0px 1px 4px rgb(82, 82, 82);
}

.breadcrumb {
  background-color: transparent;
}

.appName {
  color: white;
}

.MuiFormControlLabel-label,
.labelFormControl {
  font-size: 13px !important;
}

.titleTable {
  font-size: 12px;
  text-align: center;
}

.radioCustom {
  margin: 0px auto !important;
}

.errorLabelValidator {
  color: red;
  font-size: 10px;
}

.ejUserNote,
.positionClass label {
  font-size: 11px;
}

.tableSKills th {
  font-weight: bolder;
  font-size: 12px;
}

.tableSKills td {
  /* font-weight: bolder; */
  font-size: 11px;
}

.emailSidebar {
  font-size: 12px;
}

.btnsmall {
  font-size: 12px;
  padding: 4px;
}

.MuiExpansionPanelDetails-root {
  display: block !important;
}

.tableCustom {
  font-size: 12px;
}

.tableCustom td {
  padding: 3px;
}



.rowSelected {
  background-color: #1558d4;
  color: #fff;
}

.rowSelectedBorder {
  border-bottom: 3px solid #008c31 !important;
}

.skillTitleEvaluation {
  font-size: 25px;
  font-weight: lighter;
}

.hr_custom {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.tableBodyScrollable table,
.tableBodyScrollable tr td {
  border-top: 1px solid rgb(184, 184, 184);
}

.tableBodyScrollable tbody {
  display: block;
  height: 350px;
  overflow: auto;
}

.tableBodyScrollable thead,
.tableBodyScrollable tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.tableBodyScrollable thead {
  width: calc(100% - 1em);
}

.tableBodyScrollable table {
  width: 400px;
}

.tableBodyScrollable {
  font-size: 12px;
}

.infoLittleText {
  font-size: 13px;
}

.iconSizeSmall {
  font-size: 12px;
}

/* TIMELINE */
.message-item {
  margin-bottom: 25px;
  margin-left: 40px;
  position: relative;
}

.message-item .message-inner {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 10px;
  position: relative;
}

.message-item .message-inner:before {
  border-right: 10px solid #ddd;
  border-style: solid;
  border-width: 10px;
  color: rgba(0, 0, 0, 0);
  content: "";
  display: block;
  height: 0;
  position: absolute;
  left: -20px;
  top: 6px;
  width: 0;
}

.message-item .message-inner:after {
  border-right: 10px solid #fff;
  border-style: solid;
  border-width: 10px;
  color: rgba(0, 0, 0, 0);
  content: "";
  display: block;
  height: 0;
  position: absolute;
  left: -18px;
  top: 6px;
  width: 0;
}

.message-item:before {
  background: #fff;
  border-radius: 2px;
  bottom: -30px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  content: "";
  height: 100%;
  left: -30px;
  position: absolute;
  width: 3px;
}

.message-item:after {
  background: #fff;
  border: 2px solid #ccc;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  content: "";
  height: 15px;
  left: -36px;
  position: absolute;
  top: 10px;
  width: 15px;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.message-item .message-head {
  border-bottom: 1px solid #eee;
  margin-bottom: 8px;
  padding-bottom: 8px;
}

.message-item .message-head .avatar {
  margin-right: 20px;
}

.message-item .message-head .user-detail {
  overflow: hidden;
}

.message-item .message-head .user-detail h5 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.message-item .message-head .post-meta {
  float: left;
  padding: 0 15px 0 0;
}

.message-item .message-head .post-meta>div {
  color: #333;
  font-weight: bold;
  text-align: right;
}

.post-meta>div {
  color: #777;
  font-size: 12px;
  line-height: 22px;
}

.message-item .message-head .post-meta>div {
  color: #333;
  font-weight: bold;
  text-align: right;
}

.post-meta>div {
  color: #777;
  font-size: 12px;
  line-height: 22px;
}

/* END TIME LINE**/

.madeUpLabel {
  font-size: 12px;
  color: gray;
}

.dropdown-menu {
  left: -102px !important;
}

.dropdown-toggle {
  color: white !important;
}

.drp-contain {
  display: grid;
  grid-template-columns: repeat(5, 1fr)
}

.customTableTD th,
.customTableTD td {
  padding: 1px 0px !important;
  vertical-align: middle !important;
}

.bg-orange {
  background-color: orangered;
}

.labelButtons {
  font-size: 12px;
  color: #9c9ea0;
}

.textSmall {
  font-size: 14px;
  padding: 10px;
}

.textSmallLH {
  font-size: 12px;
  line-height: initial;
}

.titleExpandableTalentProcess {
  font-size: 15px;
}

/* jim */
.form-row {
  margin-bottom: 12px;
}

.form-row>.col,
.form-row>[class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
  display: inline-table;
}

.paddingleft0 {
  padding-left: 0;
}

.lineheight {
  line-height: 3;
}

.alignright {
  text-align: right;
  margin-top: 11px;
  margin-right: 30px;
  text-decoration: underline;
}

.wbt {
  margin-right: 10px;
  border-color: black;
  background-color: white;
  color: black;
}

nav.navbar {
  height: 58px;
}

.ic_img {
  transform: scale(0.8);
}

.btnw100 {
  width: 100px !important;
}

.titleInd {
  padding: 2px;
}

.dropDownCustomClass {
  font-size: 12px;
}

.textFeedbackDrawer {
  font-size: 12px;
  text-align: justify;
}

.tableEvaluationsTalent {
  font-size: 12px;
}

.variables {
  background-color: #e6e6e6b3;
  padding: 3px;
  width: auto;
  font-size: 12px;
}

.variables ul {
  list-style: none;
}

.variables li {
  border: 1px dashed #fff;
  margin: 3px;
  cursor: pointer;
  display: inline-block;
  padding: 2px 5px;
  border-radius: 10px;
  background-color: white;
}

.variables li:hover {
  background-color: dodgerblue;
  color: white;
}

.textAreaEmailTemplate {
  font-size: 16px !important;
}

.fixStartRating {
  padding-top: -5px;
}

.createNWH {
  background: #f6f6f6;
  padding: 10px !important;
  border-radius: 6px;
  border: 1px solid #dedede;
}

.colorWhite {
  color: white;
}

.checklistOfCompletedProfile {
  font-size: 0.9em;
  text-align: left;
  /*width: 80%;*/
  background: #fff;
  padding: 2px 5px;
  border-radius: 5px;
  /*border: 1px solid #dedede;*/
  margin-bottom: 10px;
}

.card_selection:hover {
  background: #eaeaea;
  transition: all 0.5s ease;
}

.selectedCard {
  background: #eaeaea;
}

.columnSmallText {
  font-size: 0.8em;
}

.quoteFeedback {
  background: #f5f5f5;
  border-radius: 5px;
  padding: 4px;
}

.addShadowBox {
  box-shadow: 0px 0px 5px #bdbdbd;
}

.colorTitleWidget {
  color: #232323;
}

.tableAssets th {
  text-align: right;
}

.text-primary-badge,
.text-warning-badge,
.text-danger-badge,
.text-info-badge,
.text-default-badge {
  border-radius: 5px;
  padding: 1px 3px;
}

.text-primary-badge {
  text-align: center;
  color: white;
  background: dodgerblue;
}

.text-warning-badge {
  text-align: center;
  color: white;
  background: #fff330;
}

.text-danger-badge {
  text-align: center;
  color: white;
  background: #e71212;
}

.text-info-badge {
  text-align: center;
  color: white;
  background: #04beae;
}

.text-default-badge {
  border-radius: 5px;
  color: #1d1d1d;
  background: #c1c1c1;
}

.burlywood {
  color: burlywood;
}

.uploadFileColor {
  color: #2aacee;
}

.redIcon {
  color: red !important;
}

.font-size-sm {
  font-size: 12px;
}

.sidebarTalentName {
  font-size: 1em;
  font-weight: bolder;
  text-align: center;
}

.sidebarInfoText9em {
  font-size: 0.9em;
  word-break: break-all;
}

.text9em {
  font-size: 0.8em;
}

.profilePublicWidth {
  max-width: 80rem;
}

.minHeight100 {
  min-height: 100px;
}

.ap_frame_border {
  border-radius: 5px;
}

.my-border {
  padding: 1rem;
  box-shadow: 5px 5px 5px 0.5px lightblue;
  margin: 4rem;
  background-color: rgb(243, 243, 250);
}

.no_appraisal {
  opacity: 0.1;
  height: 20vh;
  width: auto;
}

.noappraisal {
  width: 2rem;
  height: 2rem;
  flex-direction: column;
  position: absolute;
  bottom: 4rem;
  margin-right: 11rem;
  font-size: 1.5rem;
  font-weight: 600;
}

.no_currentappraisal {
  opacity: 0.1;
  height: 65vh;
  width: auto;
}

.nocurrentappraisal {
  width: 16.8rem;
  height: 10rem;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  bottom: 8rem;
}

.appraisal-finalAccordion {
  padding: 15px;
  border-radius: 25px;
}

.my-icon {
  bottom: 5px;
  position: relative;
  opacity: 0.7;
  padding: 3px;
}

.my-icon-final {
  bottom: 5px;
  position: relative;
  opacity: 0.7;
  padding: 3px;
  margin-left: -12px;
}

/* APPRAISAL CSS */
.custom {
  margin-top: 2rem;
  font-size: 13px;
  font-weight: lighter;
}

.img {
  width: 20px;
  height: 20px;
}

.imgs {
  width: 25px;
  height: 25px;
}

.status {
  justify-content: space-between;
}

.appraisee {
  width: 12px;
  height: 12px;
}

/* .appraisees {
  width: 14px;
  height: 14px;
} */

#circle {
  width: 15px;
  height: 15px;
  background: green;
  border-radius: 50%;
  text-align: left;
}

/* .Appraisee{
   background-image: url("/images/Apprasee.png");
  background-size: cover;
  background-position: center;
  width: 300px;
  height: 8rem;
  color: white;
  margin: 5px;
} */
.hr {
  width: 10px;
  height: 10px;
}

.img-responsive {
  max-width: 80%;
  max-height: auto;
}

/* fileuploader css */
/* section {
  padding: 2rem 0;
} */

.my-label {
  border: none;
  border-radius: 20px;
  width: 8rem;
  height: 2rem;
  color: black;
  margin-right: 8px;
  /* margin: 0 auto;
  display: flex;
  flex-direction: column; 
  justify-content: center;  */
  /* align-items: center; */
  /* border: 1px dotted black;
  /* border-radius: 30px;
  
  cursor: pointer;
  font-size: large;
  margin-top: 1.5rem; */
}

/* label span {
  padding-top: 0.5rem;
} */

/* input {
  display: none;
} */

/* img {
  padding: 0;
  margin: 0;
} */

.myfiles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.myfile {
  margin: 1rem 0.5rem;
  position: relative;
  /* box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px; */
  border: 1px solid black;
  border-radius: 45px;
  width: 60%;
}

.myfile button {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  border: none;
  color: white;
  background-color: lightcoral;
  border-radius: 20px;
}

.myfile button:hover {
  background-color: red;
}

.myfile p {
  padding: 0 0.5rem;
  margin: 2;
}

.upload-btn {
  /* cursor: pointer;
  display: block;
  margin: 0 auto; */
  border: none;
  border-radius: 20px;
  width: 10rem;
  height: 2rem;
  color: black;
  /* background-color: grey; */
  margin-left: 7rem;
}

/* .my-label:hover,
.upload-btn:hover {
  background: rgb(197, 197, 197);
} */

.error {
  text-align: center;
}

.error span {
  color: red;
}

.section {
  display: flex;
}

.count {
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  color: white;
  background-color: grey;
  border-radius: 20px;
}

.filename {
  margin-left: 1.5rem;
}

.PlusBtn {
  height: 32px;
  width: 32px;
  border-radius: 50rem;
  background-color: rgb(8, 105, 250);
  color: white;
  border: none;
}

.bg {
  border-radius: 5px;
  background: rgb(255, 255, 255);
  padding: 50px;
  box-shadow: 5px 5px 5px #aaaaaa;
}

.award-image {
  width: 20px;
  margin-right: 8rem;
}

.toggle {
  height: 40px;
  cursor: pointer;
  background-color: white;
  border: unset;
}

.content {
  width: auto;
  height: auto;
  margin-top: 10px;
  display: none;
  margin-left: -6rem;
  margin-top: 2rem;
}

.show {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* APPRAISAL CSS */
.minHeight200 {
  min-height: 200px;
}

.minHeight50 {
  min-height: 50px;
}

.fontSize12 {
  font-size: 12px;
}

.displayNone {
  display: none;
}

.ej_customLabels {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 13px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.small_status {
  margin-right: 120px;
}

.sm_status {
  margin-right: 200px;
}

.final_st {
  margin-right: 50px;
}

.badgeCustom {
  height: 20px;
  border: 1px solid #c0c0c4;
  color: white;
  font-size: 12px;
  border-radius: 10px;
  padding: 5px 5px;
}

.customBadge {
  height: 20px;
  border: 1px solid #c0c0c4;
  color: white;
  font-size: 12px;
  border-radius: 10px;
  margin-bottom: 3px;
  padding: 2px 2px;
}

.d-menu {
  z-index: 1000;
}

.target-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0.5em 1rem;
}

a {
  text-decoration: none;
  color: #707075;
}

.target-form-paper {
  max-width: 1100px;
  min-height: 450px;
}

/* .title-goal {
  display: none;

  margin-left: 2.3rem;

} */

/* .evaluator-toggle {
  margin-left: -1.3rem;
} */

.customBtn {
  width: 130px !important;
  font-size: 11px;
}

.title-goal {
  display: flex;
  margin-left: -2px;

  flex-wrap: wrap;
}

.title-goal b {
  width: 100px;
}

.title-goal input {
  width: 400px;
  margin-left: 15px;
}

.weight {
  display: flex;
  flex-wrap: wrap;
}

.weight input {
  width: 100px;
  margin-left: 22px;
}

.target-goal {
  display: flex;
  flex-wrap: wrap;
}

.target-goal input {
  width: 100px;
  margin-left: 22px;
}

.my-drop {
  margin-left: 5.2rem;
}

.my-text {
  font-size: 12px;
  font-weight: 400;
}

.ap_eval {
  display: grid;
}

@media screen and (max-width: 720px) {
  .no_currentappraisal {
    opacity: 0.1;
    height: 40vh;
    width: auto;
  }

  .nocurrentappraisal {
    width: 10.8rem;
    height: 10rem;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    bottom: 3rem;
    z-index: -1;
  }
}

@media screen and (max-width: 465px) {
  .my-border {
    padding: 1rem;
    box-shadow: 5px 5px 5px 0.5px lightblue;
    background-color: rgb(243, 243, 250);
    width: 70%;
  }
}

@media screen and (max-width: 1280px) {
  .ap_per {
    padding: 1rem;
  }

  .ap_btn {
    margin-bottom: 1rem;
  }
}

.share-button {
  position: relative;
}

.popup {
  width: 150px;
  height: 20px;
  position: fixed;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}

.popup-items {
  margin: 15% auto;
  display: flex;
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 15px;
  /* position: absolute; */
  left: -100px;
  top: 50px;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  animation: fadein 0.5s;
}

/* .popup-item {
  transition: background-color 0.2s ease-in-out;
}
.popup-item:hover {
  background-color: lightgray;
} */

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.ap_txt {
  font-size: 15px;
  color: grey;
}

.share-buttons {
  width: 130px;
  height: 35px;
  border-radius: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: 0.3s linear;
}

.share-buttons:hover {
  transform: scale(1.1);
}

.share-buttons span {
  position: absolute;
  width: 100%;
  height: 92%;
  background: #1a7fe4;
  color: white;
  text-align: center;
  align-items: center;
  line-height: 34px;
  z-index: 999;
  transition: 0.6s linear;
  border-radius: 4px;
  font-size: 14px;
}

.share-buttons:hover span {
  transform: translateX(-100%);
  transition-delay: 0.3s;
}

.share-buttons a {
  flex: 1;
  font-size: 26px;
  color: #252525.0;
  text-align: center;
  transform: translateX(-100%);
  opacity: 0;
  transition: 0.3s linear;
}

.share-buttons:hover a {
  opacity: 1;
  transform: translateX(0);
}

.share-buttons a:nth-of-type(1) {
  transition-delay: 1s;
}

.share-buttons a:nth-of-type(2) {
  transition-delay: 0.8s;
}

.share-buttons a:nth-of-type(3) {
  transition-delay: 0.6s;
}

.share-buttons a:nth-of-type(4) {
  transition-delay: 0.4s;
}

.ap_dropdown {
  width: 1000%;
}

.statuses0,
.statusesnull,
.statuses1,
.statuses13 {
  background: #1a7fe4;
  font-size: 11px;
  color: white;
}

.my_bt.statuses1,
.my_bt.statuses13 {
  color: white !important;
}

.statuses2,
.statuses4,
.statuses6,
.statuses8,
.statuses14,
.statuses15,
.statuses16,
.statuses17 {
  background: #e5de00;
  color: black;
  font-size: 11px;
}

.statuses10,
.statuses12 {
  background: grey;
  color: black;
  font-size: 11px;
}

.statuses3,
.statuses5,
.statuses7,
.statuses9,
.statuses11 {
  background: rgb(239, 116, 116);
  font-size: 11px;
  color: black;
}

/* .statuses1,
.my_bt.statuses1,
.my_bt.statuses13,
.statuses13,
.team.statuses2,
.other.statusesll4,
.other.statusesll2,
.other.statusesl6,
.other.statuseshr8,
.team.statuses14,
.other.statuses15,
.other.statusesl16,
.other.statuseshr17,
.team.statuses5,
.team.statuses3,
.team.statuses7,
.team.statuses9,
.team.statuses11 {
  background: #1a7fe4;
  font-size: 11px;
  color: white;
} */

/* .other.statusesl6 {
  background: #1a7fe4 !important;
  font-size: 11px;
  color: white !important;
}

.statuses12,
.statuses10,
.team.statuses12,
.other.statuseshr12,
.other.statusesll12,
.other.statusesl12,
.my_bt.statuses12 {
  background: grey;
  color: black;
  font-size: 11px;
}

.statuses3,
.statuses5,
.statuses7,
.statuses9,
.statuses11,
.my_bt.statuses3,
.my_bt.statuses5,
.my_bt.statuses7,
.my_bt.statuses9,
.my_bt.statuses11,
.other.statuses3,
.other.statuses5,
.other.statuses7,
.other.statuses9,
.other.statuses11,
.other.statuseshr11,
.other.statuseshr5,
.other.statusesll5,
.other.statusesl5,
.other.statuses11 {
  background: rgb(239, 116, 116);
  font-size: 11px;
  color: black;
}

.statuses2,
.my_bt.statuses2,
.my_bt.statuses4,
.my_bt.statuses6,
.team_bt.team.statuses6,
.statuses4,
.statuses6,
.statuses8,
.statuses14,
.statuses15,
.statuses16,
.statuses17,
.team.statuses4,
.team_bt.team.statuses16,
.team.statuses17,
.team.statuses1,
.other.statuses2,
.other.statuses6 {
  background: #e5de00;
  color: black;
  font-size: 11px;
}

.other.statuses1,
.other.statusesl4,
.other.statuseshr4,
.other.statuseshr6,
.other.statuses13,
.team.statuses13,
.other.statuses14,
.other.statusesl15,
.other.statuseshr15,
.other.statuseshr16,
.other.statusesl17,
.other.statusesll17 {
  background: #e5de00;
  color: black;

  font-size: 11px;

}

.statuses18,
.other.statuses18,
.team.statuses18 {
  background: #28a745;
  font-size: 11px;
  color: white;
} */

::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-track {
  background-color: #bcd6f0;
}

::-webkit-scrollbar-thumb {
  /* background: black; */
  border: 9px solid transparent;
  border-radius: 2px;
  background-clip: content-box;
}


.my_switch {
  margin-right: 13rem;
}

.screen_switch {
  margin-right: 5rem;

}

.talent_switch {
  margin-right: 1.2rem;
}

.asset_switch {
  margin-right: 1rem;
}

.appr_switch {
  margin-right: 2.1rem;

}

.work_switch {
  margin-right: 2.1rem;

}


.screening-option {
  background-color: #EFF0F9;
}

.screening-inputbox {
  resize: none;
  overflow-y: hidden;
  border: none;
  background-color: #EFF0F9;

}


textarea.screening-inputbox:focus {
  border: none;
  outline: none;
}


.questionform-cancel {
  border: none;
  align-items: center;
  width: 110px;
  text-align: center;
  height: 30px;
  margin-right: 15px;
  background-color: #d9d9d9;
}

.questionform-save {
  border: none;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 30px;
  margin-right: 40px;
  text-align: center;
  background-color: #d9d9d9;
}
.screening-tab{

}
.screening-logs{
  display: flex;
}
.screening-logs >div{
  margin: 5px;
}
.screening-comment{ 
 position: static;

}
.screening-comments {
  
  display: flex;
  flex-direction: row; 
  align-items: flex-start;
  /* position: absolute; */
  /* justify-content: space-between; */
}
.screening-comment-image{
  /* position: absolute;
  left: 30px; */
}

.screening-timestamp {
  position: absolute;
  right:30px;
  /* margin-left: 750px; */
}

.screening-question{
  color: #989494;
  height: 30px;
}
.screening-answer{
  color: #000000;
  height: 30px;
}
.screening-question-date{
  color:#716B6B;
  position: relative
}
.screening-question-time{
  position: relative;
}

.screening-content{
  /* position:absolute ; */

}

.screening-content-comment{
  font-size: 1.1rem;
}

.screening-modal {
  background-color: #eff0f9;
}

.screening-popup {
  padding-left: 5%;
  padding-right: 6%;
  max-height: 400px;
  overflow-y: auto;
  background-color: #eff0f9;

}
.screening-questions-number{
  /* padding-top: 4px; */
  /* padding-bottom: 5px; */
}
.screening-modal-header{
  background-color: #eff0f9;

}
.screening-modal-footer{
  background-color: #eff0f9;

}
.star {
  color: red;
}

.small-text {
  font-size: 12px;
  margin-bottom: 2px;
}

.custom-input {
  background-color: #eff0f9;
  cursor: text;
  border: 1px solid #656363;
  width: 100%;
  height: 26px;
  margin-bottom: 7px ;
  margin-top: 6px;
}
.screening-numeric {
  background-color: #eff0f9;
  cursor: text;

  border: 1px solid #656363;
  width: 100%;
  height: 26px;
  margin-bottom: 7px;
  /* margin-top: 6px; */
}

.screening-option-input{
  display: flex;
  flex-wrap: wrap;
}
.screening-option-input> div{
  width: 50%;
}
.options-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.options-container > div {
  /* margin-right: 10px; */
  width: 50%;
}
.screening-radio-input {
  margin-right: 45px;
  
}
/* label {
  margin-right: 10px;
} */
.answer-type {
  margin-left: 30px;
}
.screening-close-btn{
  height: 30px;
  width: 100px;
  border: none;
  background-color: #dfe1f0;
  margin: 10px;

}
.screening-save-btn{
  margin-right: 5%;
  height: 30px;
  width: 100px;
  border: none;
  color: white;
  background-color: #0038FF;

}
