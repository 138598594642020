.backLeft{
width: 60rem;
}
.back{
    justify-content: space-around;
    
}
@media screen and (max-width: 1000px){
    .back{
        justify-content: space-evenly !important;
    }
   
    .leftSide{
        position:relative;
        height:51rem;
    }
    .para{
        position: absolute;
        top:14.7rem;
        padding:0rem 0.5rem 0.5rem 0rem;
        margin:-2px;
    }
    .para p{
        font-size:13px !important;
    }
   
    .keyPoint{
        position:absolute;
        top:5px;
    }
    .RightSide{
       /* margin:1rem -2rem; */
    }
    .keylist span{
        font-size:14px !important;
    }

}
@media screen and (max-width: 780px){
    .para{
        margin:2rem 0rem;
        }
    .leftSide{
        width: 24rem;
       }
    .para p{
        font-size:13px !important;
       }
    .back{
       height: auto;
       margin: 0rem 0rem 0rem -3rem;
       }
    .RightSide{
        margin:2rem 2rem;
     }
     .keylist span{
         font-size:13px !important;
     }
}
@media screen and (max-width: 700px){
    .logo{
        height:30px !important;
        width: auto;
    }
    .item{
        font-size: 13px;
    }
    .info2{
        flex-wrap: wrap;
    }
    .back{
     display: inline-block !important;
    }
    .backLeft{
        width:auto;
        height: auto;
        margin-left:15px;
        }
   .backRight{
    margin:2rem 1rem 1rem 3rem; 
    height: 40.5rem; 
   }
    .leftSide{
        position:none;
        margin: -1rem;
        
        height:49rem;
        width: auto;
    }
    .para{
       position: none;
       padding:15px;
    }
   
    .keyPoint{
        padding:15px;
    }
    .RightSide{
        margin:0rem 0rem;
        height: 30rem;
    }

}
@media screen and (max-width: 380px){
    .item a{
        font-size: 10px;
       
    }
   
    .leftSide{
       height:60rem;
       margin-left: 13px;
    }
    .backRight{
        margin:2rem 1rem 1rem 3rem; 
        height: 50rem; 
       }
       .connect h3{
        font-size: 20px;
        padding-top:10px;
       }
   
}